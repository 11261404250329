/*
Render rich text if bodyRichText prop is provided, otherwise render Markdown from the body prop. If there is neither, return empty html.
 */
//@ts-ignore
import React, {FC} from 'react';
import {BLOCKS} from '@contentful/rich-text-types';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {ContentfulRemark} from "../utils/common-types";
import {MarkdownContainer} from "./typography";
import {ArticlePreviewHorizontal} from "./article-preview";
import {RichTextButtonRow} from "../components/buttons";

//@ts-ignore
const EmbeddedAsset = ({node}) => {
  switch (node.sys.contentType.sys.id) {
    case 'blogPost':
      return <ArticlePreviewHorizontal article={node}/>
    case 'buttonRow':
      return <RichTextButtonRow links={node.buttonLinks}/>
    default:
      return <></>
  }
}

const RichTextOrMarkdown: FC<{bodyRichText?: {raw: string, references: any[]}, body?: ContentfulRemark}> = ({bodyRichText, body}) => {
  //todo: custom render linked assets https://www.contentful.com/blog/2021/04/14/rendering-linked-assets-entries-in-contentful/
  if (bodyRichText && bodyRichText.raw) {
    const referenceMap = new Map()
    for (let i = 0; i < bodyRichText.references.length; i++) {
      referenceMap.set(bodyRichText.references[i].contentful_id, bodyRichText.references[i])
    }

    const getNodeRefData = (node) => referenceMap.get(node.data.target.sys.id)

    const renderOptions = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
          return (
            <img
              className="w-full py-6"
              src={`https://${getNodeRefData(node).file.url}`}
              alt={node.data.target.description || node.data.target.alt}
            />
          )
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node) => (<EmbeddedAsset node={getNodeRefData(node)} />)
      }
    }

    return (<div className="py-8 text-justify markdown font-body text-xl use-source-sans-pro">
      {documentToReactComponents(JSON.parse(bodyRichText.raw), renderOptions)}
    </div>)
  }
  // return markdown if no richtext
  if (body && body.childMarkdownRemark) {
    return (
      <MarkdownContainer className="py-8 text-justify" useSourceSans>
        {body.childMarkdownRemark.html}
      </MarkdownContainer>
    )
  }
  return <></>
}

export default RichTextOrMarkdown;
