//@ts-ignore
import React, {FC, useState} from 'react';
import {Articles} from "../utils/common-types";
import {ArticlePreviewHorizontal} from '../components/article-preview'

const ArticleList: FC<{articles: Articles}> = ({articles}) => {
  return (
    <>
      <div className={`pt-4 grid grid-cols-1 gap-4`}>
        {articles.map((article, index) => (
          <div key={index} className="mb-4">
            <ArticlePreviewHorizontal article={article}/>
          </div>
        ))}
      </div>
    </>
  )
}

export default ArticleList;
