import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import {LGHeader, MarkdownContainer} from "../components/typography";

import './blog-post.css';
import ArticleList from "../components/article-list";
import RichTextOrMarkdown from "../components/rich-text-or-markdown";

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    return (
      <Layout location={this.props.location}>
        <Helmet title={`${post.title} | ${siteTitle}`}>
          <meta property="og:title" content={post.title} />
          <meta property="og:image" content={post.heroImage && post.heroImage.file.url} />
          {/*<meta property='twitter:title' content={post.title}/>*/}
          {/*<meta property='twitter:image' content={post.heroImage.file.url}/>*/}
          {/*<meta name="twitter:card" content="summary_large_image"/>*/}
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:site" content="https://kingcountyequitynow.com"/>
          <meta name="twitter:creator" content="@KCEquityNow"/>
          <meta name="twitter:title" content={post.title}/>
          <meta name="twitter:image" content={`https:${post.heroImage && post.heroImage.fluid.src}`}/>
        </Helmet>
        <div className="max-w-screen-lg mx-auto p-8 md:p-16 text-white">
          <div>
            {post.heroImage &&
              <Img
                alt={post.title}
                fluid={post.heroImage.fluid}
              />
            }
          </div>
          <div className="wrapper">
            <LGHeader className="text-center py-6">{post.title}</LGHeader>
            <RichTextOrMarkdown body={post.body} bodyRichText={post.bodyRichText}/>
          </div>
          {post.articles && <ArticleList articles={post.articles}/>}
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      heroImage {
        fluid(maxWidth: 1180, maxHeight: 663, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
        file {
          url
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      bodyRichText {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          fixed(width: 1600) {
            width
            height
            src
            srcSet
          }
          alt: description
          file {
            url
          }
        }
        ... on ContentfulBlogPost {
          contentful_id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          title
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
        }
        ... on ContentfulButtonRowForRichText {
          contentful_id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          buttonLinks {
            headerNavLinkName
            linkNameOverride
            linkType
            linkUrl
          }
        }
        ... on ContentfulSolutionPage {
          contentful_id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
        }
        ... on ContentfulArticle {
          title
          url
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
      articles {
        ... on ContentfulArticle {
          title
          url
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
